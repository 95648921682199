<template>
	<section class="summitVenueSection" id="summitVenueSection">
		<div class="proofMainContainer summitVenueContainer">
			<div class="row">
				<div class="col-lg-6 mb-lg-0 mb-5 text-center">
					<h1>SUMMIT VENUE</h1>
					<p>
						The main venue for the conference is SPARK Europe, where the full
						conference days will take place on the weekend of September 28th and 29th, 2024. The Summit begins with the opening night party scheduled for the 27th of September.
					</p>
					<p>
						The address is Junghofstraße 16, 60311 Frankfurt am Main, Germany.
					</p>
					<p>
						Questions? Contact us at
						<a href="mailto:hello@powsummit.com">hello@powsummit.com</a>
					</p>
				</div>
				<div class="col-lg-6">
					<img src="@/assets/images/venue-2024.jpg" alt="" />
				</div>
			</div>
		</div>
	</section>
	<section class="whereToStaySection">
		<div class="proofMainContainer whereToStayContainer">
			<div class="row">
				<div class="col-lg-6 pe-lg-4 pe-3 order-lg-1 order-2">
					<img src="@/assets/images/holidayinn.png" alt="" />
				</div>
				<div class="col-lg-6 mb-lg-0 mb-5 order-lg-2 order-1 text-center">
					<h1>ACCOMMODATIONS</h1>
					<p>
						Our recommended hotel is the Holiday Inn Alte Oper which is a 4 minute drive (or 11
minute walk) from the Stages venue.
					</p>
                   <p> We have secured a special rate for all POW Summit attendees. Rooms are expected to fill up fast due to
Oktoberfest, so book your ticket now!</p>
					<p>Mainzer Landstraße 27, 60329 Frankfurt am Main, Germany</p>
					<a
						href="https://www.ihg.com/holidayinn/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=FRAAO&qCiD=27&qCiMy=082024&qCoD=30&qCoMy=082024&qGrpCd=MTG&setPMCookies=true&qSHBrC=HI&qDest=Mainzer%20Landstrasse%2027%2C%20Frankfurt%2C%20DE&srb_u=1"
						class="text-decoration-none"
						target="_blank"
						><button class="bookNowBtn">BOOK NOW</button></a
					>
				</div>
			</div>
		</div>
	</section>

</template>
