<template>
	<section class="mediaPartnersSection">
		<h1>Media Sponsors</h1>
		<div class="mediaPartnersContainer">
			<a target="_blank" href="https://icoholder.com/">
				<img src="@/assets/images/icoholder.png" alt="Icoholder" />
			</a>
			<a target="_blank" href="https://www.cryptonewsz.com/">
				<img src="@/assets/images/cryptonewsz.png" alt="CryptoNewsZ" />
			</a>
			
			<a target="_blank" href="https://cryptoevents.global/">
				<img src="@/assets/images/cyptoevents-logo.png" alt="CryptoEvents" />
			</a>

			<a target="_blank" href="https://events.coinpedia.org/">
				<img src="@/assets/images/coinpedia.png" alt="Coinpedia" />
			</a>
			<a target="_blank" href="https://www.cryptopolitan.com/">
				<img src="@/assets/images/cryptopolitian.png" alt="Cryptopolitian" />
			</a>
			<a target="_blank" href="https://bitcoinist.com/">
				<img src="@/assets/images/bitcoinist.png" alt="Bitcoinist" />
			</a>
			<a target="_blank" href="https://www.newsbtc.com/">
				<img src="@/assets/images/newsbtc.png" alt="NewsBTC" />
			</a>
		</div>
	</section>
</template>
