<template>
	<section class="summitVenueSection" id="summitVenueSection">
		<div class="proofMainContainer summitVenueContainer">
			<div class="row">
				<div class="col-lg-6 mb-lg-0 mb-5 text-center">
					<h1>SUMMIT VENUE</h1>
					<p>
						The main venue for the conference is O2 universum, where the full
						conference days will take place on September 26th to 27th, 2023.
					</p>
					<p>
						The address is Českomoravská 2345/17, 190 00 Praha 9-Libeň, Czechia.
					</p>
					<p>
						The opening party will take place at Vnitroblock on September 25th,
						2023.
					</p>
					<p>
						Questions? Contact us at
						<a href="mailto:hello@mmhgroup.io">hello@mmhgroup.io</a>
					</p>
				</div>
				<div class="col-lg-6">
					<img src="@/assets/images/venue.png" alt="" />
				</div>
			</div>
		</div>
	</section>

	<section class="whereToStaySection">
		<div class="proofMainContainer whereToStayContainer">
			<div class="row">
				<div class="col-lg-6 pe-lg-4 pe-3 order-lg-1 order-2">
					<img src="@/assets/images/hotel-img.png" alt="" />
				</div>
				<div class="col-lg-6 mb-lg-0 mb-5 order-lg-2 order-1 text-center">
					<h1>ACCOMMODATIONS</h1>
					<p>
						The <span>STAGES Hotel Prague</span> is located in the newly
						developed residential and business district next to O2 arena and O2
						universum.
					</p>
					<p>Českomoravská 19a, 190 00 Praha 9, Czechia</p>
					<a
						href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1683544360518&key=GRP&app=resvlink"
						class="text-decoration-none"
						target="_blank"
						><button class="bookNowBtn">BOOK NOW</button></a
					>
				</div>
			</div>
		</div>
	</section>
</template>
