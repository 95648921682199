<template>
	<section class="activeSponsorsSection" id="activeSponsorsSection">
		<h1>Sponsors</h1>
		<div class="activeSponsorsContainer">
			

			<div class="silver">
				<h3>Silver</h3>
						<div>
					<a target="_blank" href="https://rootstock.io/">
						<img src="@/assets/images/rootstock-logo.png" alt="Rootstock" />
					</a>
					<a target="_blank" href="https://www.blockscout.com/">
						<img src="@/assets/images/blockscout-logo.png" alt="Blockscout" />
					</a>
				</div>
			</div>
		</div>
	</section>
</template>
